import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

export default function PageHeading({
  heading,
  subheading,
  BgImg,
  BtnLink,
  BtnLabel,
  publishedDate,
  className,
}) {
  return (
    <Container
      fluid
      className={`page-heading ${className}`}
      style={{
        backgroundImage: `linear-gradient(
        73.36deg,
        rgb(40, 89, 67, .81) 0%,
        rgb(40, 89, 67, .81) 154.1%
      ),url(${BgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Container>
        <Row>
          <Col
            lg="8"
            className="mx-auto text-center text-white"
            style={{ zIndex: "2" }}
          >
            {" "}
            <h1
              className="page-hero-heading text-white text-uppercase display-5 pb-3 mt-0 mb-0"
              style={{ lineHeight: "4rem" }}
              dangerouslySetInnerHTML={{
                __html: heading,
              }}
            />
            {subheading !== undefined ? (
              <h4 className="page-hero-subheading text-white  mt-0 mb-5 text-uppercase">
                {subheading}
              </h4>
            ) : null}
            {publishedDate !== undefined ? (
              <span className="text-white-50 small">
                <i className="fa fa-calendar-o pr-1" />
                {publishedDate}
              </span>
            ) : null}
            {BtnLink !== undefined ? (
              <Link to={BtnLink} className="btn">
                {BtnLabel}
              </Link>
            ) : null}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
